import React, { useEffect, useMemo } from 'react'
import Layout from '@/layouts/MainLayout'
import {
  MAXIMUM_POSSIBLE_CATEGORY,
  MINIMUM_POSSIBLE_CATEGORY,
  QUIZ,
} from '../services/constans'
import SEO from '@/components/seo'
import { Events } from '../components'
import { useInfiniteQuery } from 'react-query'
import { navigate } from 'gatsby'
import { CategoryCascad } from '../cascad-screens/CategoryCascad'
import getEvents from '../utils/getEvents'
import InfiniteScroll from 'react-infinite-scroll-component'

function Category({ params, location }) {
  const category = useMemo(() => params['*'], [params])
  const isQuiz = useMemo(() => category === QUIZ, [category])

  useEffect(() => {
    if (
      !category ||
      category > MAXIMUM_POSSIBLE_CATEGORY ||
      category < MINIMUM_POSSIBLE_CATEGORY
    ) {
      navigate('/404')
    }
  }, [category])

  const {
    data,
    fetchNextPage,
    hasNextPage,
    isFetchingNextPage,
    isLoading,
  } = useInfiniteQuery(['events', location.pathname, category], getEvents, {
    getNextPageParam: lastPage => lastPage.next,
    onError: () => navigate('/404'),
  })

  const dataLength = useMemo(
    () =>
      data?.pages.reduce((counter, page) => counter + page.results.length, 0),
    [data]
  )

  return (
    <Layout location={location} category={category}>
      <SEO title={'Мероприятия'} />

      {!isLoading && (
        <InfiniteScroll
          dataLength={dataLength}
          next={fetchNextPage}
          hasMore={hasNextPage}
          style={{ overflow: 'hidden' }}
          loader={
            isFetchingNextPage && (
              <CategoryCascad count={20} hasHeader={false} />
            )
          }
        >
          {dataLength && (
            <Events
              heading={'Мероприятия'}
              style={{ overflow: 'hidden' }}
              events={data?.pages}
              categoryId={category}
              isQuiz={isQuiz}
            />
          )}
        </InfiniteScroll>
      )}
    </Layout>
  )
}
export default Category
